var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order" },
    [
      _c(
        "van-tabs",
        {
          attrs: { animated: "" },
          on: { change: _vm.handleChange },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        _vm._l(_vm.tabs, function(item) {
          return _c("van-tab", {
            key: item,
            attrs: { name: item, title: item }
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "order-list" },
        [
          _vm.orders.length == 0
            ? _c("div", { staticClass: "no-data" }, [
                _c("i", { staticClass: "iconfont icon-dingdan" }),
                _vm._v("暂无数据 ")
              ])
            : _vm._e(),
          _vm._l(_vm.orders, function(item) {
            return _c("div", { key: item.orderNo, staticClass: "order-item" }, [
              _c("div", { staticClass: "order-header" }, [
                _c("div", { staticClass: "time" }, [
                  _vm._v("下单时间：" + _vm._s(item.createTime))
                ]),
                _c("div", { staticClass: "status" }, [
                  _vm._v(_vm._s(item.payStatus))
                ])
              ]),
              _c("div", { staticClass: "order-body" }, [
                _vm._m(0, true),
                _c("div", { staticClass: "order-desc" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.prdName))
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _vm._v("订单类型：" + _vm._s(item.orderType))
                  ])
                ]),
                _c("div", { staticClass: "order-price" }, [
                  _c("div", { staticClass: "real-price" }, [
                    _vm._v("￥" + _vm._s(item.orderAmount))
                  ]),
                  _c("div", { staticClass: "history-price" }, [
                    _vm._v("(原价￥" + _vm._s(item.payAmount) + ")")
                  ]),
                  _c("div", { staticClass: "count" }, [_vm._v("X1")])
                ])
              ])
            ])
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "order-pic" }, [
      _c("img", {
        staticClass: "img-prod",
        attrs: { src: "/images/pic-prod.jpg", alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }