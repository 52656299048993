import Vue from "vue";
import VueRouter from "vue-router";

import Login from '@/views/user/Login'
import Layout from '@/components/Layout'
import Modify from '@/views/Modify'
import Settings from '@/views/Settings'
import Home from '@/views/Home'
import Order from '@/views/Order'
import OrderHistory from '@/views/OrderHistory'
import Edu from '@/views/Edu'
import Agent from '@/views/Agent'
import Assist1 from '@/views/Assist1'
import Assist2 from '@/views/Assist2'
import Policy from '@/views/Policy'
import Family from '@/views/Family'
import Customization from '@/views/Customization'
import About from '@/views/About'
import Protocol from '@/views/Protocol'
import Article from "@/views/Article";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        meta: {title: '首页'},
        component: Layout,
        children: [
            {
                path: "home",
                component: Home
            },
            {
                path: "modify",
                name: "modify",
                meta: {title: '修改绑定手机号'},
                component: Modify
            },
            {
                path: "settings",
                name: "settings",
                meta: {title: '设置'},
                component: Settings
            },
            {
                path: "order",
                name: "order",
                meta: {title: '我的订单'},
                component: Order
            },
            {
                path: "orderhistory",
                name: "orderhistory",
                meta: {title: '历史保单'},
                component: OrderHistory
            },
            {
                path: "edu",
                name: "edu",
                meta: {title: '智保课堂'},
                component: Edu
            },
            {
                path: "agent",
                name: "agent",
                meta: {title: '我的顾问'},
                component: Agent
            },
            {
                path: "assist1",
                name: "assist1",
                meta: {title: '理赔协助'},
                component: Assist1
            },
            {
                path: "assist2",
                name: "assist2",
                meta: {title: '理赔协助'},
                component: Assist2
            },
            {
                path: "policy",
                name: "policy",
                meta: {title: '保单详情'},
                component: Policy
            },
            {
                path: "family",
                name: "family",
                meta: {title: '家庭保单'},
                component: Family
            },
            {
                path: "customization",
                name: "customization",
                meta: {title: '个人定制'},
                component: Customization
            },
            {
                path: "about",
                name: "about",
                meta: {title: '关于我们'},
                component: About
            },
            {
                path: "protocol",
                name: "protocol",
                meta: {title: '服务协议'},
                component: Protocol
            },
        ]
    },
    {
        path: "/article/:id",
        name: "article",
        meta: {title: '文章详情'},
        component: Article
    },
    {
        path: "/login",
        name: "login",
        meta: {title: '登录'},
        component: Login
    }
]

var router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path == "/") {
        next({path: '/home'})
    }
    next();
})

export default router;
