<template>
    <div class="agent">
        <div class="wrapper" ref="wrapper">
            <div class="agent-card">
                <div class="card-header">
                    <div class="agent-avatar">
                        <img :src="avatar" alt="" class="img-avatar" />
                    </div>
                    <div class="agent-name">
                        <div class="name">陈倩倩</div>
                        <div class="job">资深保险代理人</div>
                    </div>
                    <div class="agent-sign">
                        <div class="sign">
                            <i class="iconfont icon-jinpaizixun"></i>
                            金牌顾问
                        </div>
                    </div>
                </div>
                <div class="agent-grade">
                    <van-row>
                        <van-col span="6">
                            <div class="count">7.5</div>
                            从业年限
                        </van-col>
                        <van-col span="6">
                            <div class="count">170</div>
                            服务客户
                        </van-col>
                        <van-col span="6">
                            <div class="count">315</div>
                            保单
                        </van-col>
                        <van-col span="6">
                            <div class="count">44</div>
                            理赔(万)
                        </van-col>
                    </van-row>
                </div>
            </div>
        </div>
        <div class="contact-type">
            <van-row gutter="10">
                <van-col span="12">
                    <div class="item" @click="handleQrCode">
                        <div class="title">
                            <i class="iconfont icon-weixin"></i>加微信
                        </div>
                        随时答疑解惑
                    </div>
                </van-col>
                <van-col span="12">
                    <a href="tel:0755-36904299">
                        <div class="item">
                            <div class="title">
                                <i class="iconfont icon-dianhua"></i>打电话
                            </div>
                            立即咨询
                        </div>
                    </a>
                </van-col>
<!--                <van-col span="8">-->
<!--                    <div class="item" @click="handleToCanvas">-->
<!--                        <div class="title">-->
<!--                            <i class="iconfont icon-haoyoutuijian"></i>分享名片-->
<!--                        </div>-->
<!--                        向好友推荐TA-->
<!--                    </div>-->
<!--                </van-col>-->
            </van-row>
        </div>

        <h3 class="box-title">个人标签</h3>
        <div class="box tags">
            <div class="tag">财富管理</div>
            <div class="tag">专业负责</div>
            <div class="tag">敬业爱岗</div>
            <div class="tag">资深保险顾问</div>
            <div class="tag">理赔专家</div>
        </div>
    </div>
</template>

<script>
import avatar from "@/assets/avatar.jpg";
import html2canvas from "html2canvas";
import { ImagePreview } from "vant";
export default {
    data() {
        return {
            avatar
        };
    },
    methods: {
        handleToCanvas() {
            html2canvas(this.$refs.wrapper).then(canvas => {
                ImagePreview([canvas.toDataURL()]);
            });
        },
        handleQrCode() {
            ImagePreview(["/images/qrcode.jpg"]);
        }
    }
};
</script>

<style lang="less">
.agent {
    .wrapper {
        padding: 0.5rem 0;
    }
    .agent-card {
        background: url(../assets/bg-card.jpg);
        background-size: cover;
        border-radius: 7px;
        margin: 0 0.3rem;
        .card-header {
            display: flex;
            justify-content: space-between;
            .agent-avatar {
                width: 1.4rem;
                margin: -0.3rem 0 0 0.3rem;
                .img-avatar {
                    width: 100%;
                    border-radius: 50%;
                    border: 3px solid #fff;
                    box-sizing: border-box;

                    box-sizing: border-box;
                }
            }
            .agent-name {
                flex-grow: 1;
                padding: 0.3rem 0 0 0.3rem;
                color: #9accff;
                .name {
                    color: #fff;
                    font-size: 0.4rem;
                    margin-bottom: 0.1rem;
                }
            }
            .agent-sign {
                padding: 0.5rem 0.3rem 0 0;
                .sign {
                    padding: 0.01rem 0.2rem 0.01rem 0.5rem;
                    background: #ffefd5;
                    color: #ce9834;
                    border-radius: 0.3rem;
                    position: relative;
                    font-size: .24rem;

                    .iconfont {
                        position: absolute;
                        left: -0.2rem;
                        top: -0.15rem;
                        font-size: 0.6rem;
                    }
                }
            }
        }

        .agent-grade {
            border-top: 1px solid #3787ff;
            margin: 0.4rem 0.3rem 0;
            padding: 0.3rem 0;
            text-align: center;
            color: #9accff;
            .count {
                color: #fff;
                font-weight: 600;
                font-size: 0.5rem;
            }
        }
    }

    .contact-type {
        margin: 0 0.3rem .3rem;
        text-align: center;

        .item {
            padding: 0.2rem;
            background: #fff;
            border-radius: 8px;
            color: var(--gray);
            font-size:.28rem;
            .title {
                color: var(--deepblue);
                font-weight: 600;
                margin-bottom: 0.1rem;
                font-size: .31rem;
                .iconfont {
                    margin-right: 0.1rem;
                }
            }
        }
    }

    .box-title {
        color: var(--deepblue);
        font-size: 0.4rem;
        margin: 0 0.3rem 0;
    }

    .tags {
        padding: 0.3rem 0.3rem 0;
        .tag {
            background: #e9f1fe;
            color: #3472f7;
            display: inline-block;
            padding: 0.1rem 0.2rem;
            margin: 0 0.2rem 0.2rem 0;
        }
    }
}
</style>
