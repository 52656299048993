<template>
    <div class="policy">
        <div class="policy-header">
            <div class="policy-name">
                {{ model.prdName }}
            </div>
            保单号：{{ model.policyNo }}
            <i class="iconfont icon-baozhangzhong"></i>
        </div>
        <div class="policy-body">
            <van-row>
                <van-col span="6" class="title">
                    保险期间<br/>
                    投保人<br/>
                    被保人<br/>
                    保费<br/>
                    承保公司
                </van-col>
                <van-col span="18">
                    {{ model.insStime }}至{{ model.insEtime }}<br/>
                    {{ model.holderName }}<br/>
                    {{ model.iname }}<br/>
                    {{ model.sbillAmount }}元<br/>
                    {{ model.comName }}
                </van-col>
            </van-row>
        </div>

        <a @click="handleElecPolicy(model.policyNo, model.elecPolicy)">
            <van-cell is-link>
                <span class="cell-title" slot="title">查看电子保单</span>
            </van-cell>
        </a>
        <van-cell is-link @click="handleContact">
            <span class="cell-title" slot="title">
                理赔协助
            </span>
        </van-cell>
        <a href="tel:0755-36904299">
            <van-cell value="4006-11-7777">
                <span class="cell-title" slot="title">
                    拨打保险公司电话
                </span>
            </van-cell>
        </a>
    </div>
</template>

<script>

import axios from "@/utils/request";

export default {
    data() {
        return {
            model: {}
        };
    },
    methods: {
        getData() {
            this.model = JSON.parse(sessionStorage.getItem("policy"))
        },
        handleContact() {
            axios.post("/usercenter/add", {policyNo: this.model.policyNo})
            this.$router.push({path: "/agent"})
        },
        handleElecPolicy(policyNo, href) {
            axios.get("/usercenter/policy/downloadelecpolicy", {params: {policyNo}}).then(res => {
                var aLink = document.createElement("a");
                aLink.target = "_blank";
                aLink.href = href;
                aLink.click();
            })
        }
    },
    mounted() {
        this.getData();
    }
};
</script>

<style lang="less">
.policy {
    .policy-header {
        color: var(--gray);
        padding: 0.4rem 16px;
        background: #fff;
        margin-bottom: 1px;
        position: relative;

        .policy-name {
            font-size: 0.4rem;
            color: var(--deepblue);
            font-weight: 600;
            margin-bottom: 0.3rem;
        }

        .iconfont {
            font-size: 2rem;
            color: #c2d6fb;
            position: absolute;
            right: 16px;
            top: 0;
        }
    }

    .policy-body {
        background: #fff;
        padding: 10px 16px;
        color: var(--deepblue);
        margin-bottom: 10px;
        line-height: 180%;

        .title {
            color: var(--gray);
        }
    }

    .van-cell {
        margin-bottom: 10px;
    }
}
</style>
