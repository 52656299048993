import { render, staticRenderFns } from "./Edu.vue?vue&type=template&id=4ad4d26c&"
import script from "./Edu.vue?vue&type=script&lang=js&"
export * from "./Edu.vue?vue&type=script&lang=js&"
import style0 from "./Edu.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/9sgAcqLa/0/insurance/frontend/h5.demo.cmbpo.com/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ad4d26c')) {
      api.createRecord('4ad4d26c', component.options)
    } else {
      api.reload('4ad4d26c', component.options)
    }
    module.hot.accept("./Edu.vue?vue&type=template&id=4ad4d26c&", function () {
      api.rerender('4ad4d26c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Edu.vue"
export default component.exports