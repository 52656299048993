<template>
    <div class="family-policy">
        <div class="policy-header">
            <div class="username">Hi, {{ cname }}</div>
        </div>

        <van-tabs v-model="active" animated>
            <van-tab v-for="item in members" :key="item.id" :title="item.name"></van-tab>
        </van-tabs>
        <div class="policy-list">
            <div class="no-data" v-if="policies.length == 0">
                <i class="iconfont icon-dingdan"></i>暂无数据
            </div>
            <van-cell class="policy-item" v-for="item in policies" :key="item.policyNo">
                <div class="name">{{ item.iname }}</div>
                {{ item.prdName }}<br/>
                保单号：{{ item.policyNo }}
                <div class="status expired">
                    <i class="iconfont icon-yishixiao"></i>
                </div>
                <div>
                    <a href="javascript:void(0)" @click="handleViewDetail(item)" class="link">查看详情</a>
                    <a :href="item.elecPolicy" target="_blank" class="link">下载电子保单</a>
                </div>
            </van-cell>
        </div>
    </div>
</template>

<script>
import axios from "@/utils/request";
import NP from 'number-precision'

export default {
    data() {
        return {
            active: "",
            name: "全部",
            list: [],
            totalPerson: 0,
            totalPremium: 0,
            cname: localStorage.getItem("cname")
        };
    },
    computed: {
        policies() {
            return this.list.filter(item => this.name == '全部' || item.holderName == this.name) || [];
        },
        members() {
            var result = [{id: 0, name: "全部"}];
            this.list.forEach(item => {
                let p = result.find(a => a.name == item.iname);
                if (p != null) {
                    p.count++;
                } else {
                    result.push({id: item.policyNo, name: item.iname, count: 1})
                    this.totalPerson++;
                }
                this.totalPremium = NP.plus(this.totalPremium, item.sbillAmount);
            });
            return result;
        }
    },
    methods: {
        getData() {
            axios.get("/usercenter/policy/expirelist").then(res => {
                this.list = res.result || [];
            })
        },
        handleChange(name) {
            this.name = name;
        },
        handleViewDetail(data) {
            sessionStorage.setItem("policy", JSON.stringify(data));
            this.$router.push({path: "/policy"})
        }
    },
    mounted() {
        this.getData()
    }
};
</script>

<style lang="less">
.family-policy {
    .policy-list {
        .policy-item {
            .expired {
                .iconfont {
                    color: var(--gray)!important;
                }
            }
        }
    }
}
</style>
