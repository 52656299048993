<template>
    <div class="assist">
        <van-search v-model="keywords" placeholder="请输入您需要理赔的保单号" />

        <div class="policy-list">
            <div class="no-data" v-if="policyList.length == 0">
                <i class="iconfont icon-dingdan"></i>暂无数据
            </div>
            <van-radio-group v-model="policyNo" v-for="item in policyList" :key="item.policyNo">
                <van-radio :name="item.policyNo" icon-size="24px">
                    <div class="name">{{ item.iname }}</div>
                    {{ item.prdName }}<br>
                    保单号：{{ item.policyNo }}
                    <div class="status">
                        <i class="iconfont icon-baozhangzhong"></i>
                    </div>
                </van-radio>
            </van-radio-group>

            <van-button round type="info" block @click="handleContact" v-if="policyList.length">联系理赔顾问</van-button>
        </div>
    </div>
</template>

<script>
import axios from "@/utils/request";
export default {
    data() {
        return {
            keywords: "",
            policyNo: "",
            list: []
        }
    },
    computed: {
        policyList(){
            return this.list.filter(item => !this.keywords.trim() || this.keywords.trim() == item.policyNo);
        }
    },
    methods: {
        handleContact(){
            if(this.policyNo == ""){
                this.$dialog.alert({message: "请选择一份保单"});
            }else {
                axios.post("/usercenter/add", {policyNo: this.policyNo})
                this.$router.push({path: "/agent"})
            }
        },
        getData() {
            axios.get("/usercenter/policy/list").then(res => {
                this.list = res.result;
            })
        },
    },
    mounted() {
        this.getData();
    }
};
</script>

<style lang="less">
.assist {
    padding: 0.3rem;

    .van-search {
        border-radius: 0.2rem;
        margin-bottom: .5rem;
        .van-search__content {
            background: #fff;
        }
    }

    .van-radio {
        background: #fff;
        border-radius: 8px;
        margin-bottom: .5rem;
        padding:.3rem 0 .3rem .2rem;
        position: relative;

        .van-radio__label {
            margin-left: .3rem;
            color: var(--gray);
            .name {
                color: #333;
                font-size: .4rem;
                font-weight: 600;
                margin-bottom: .1rem;
            }
        }

        .status {
            position: absolute;
            right: -.2rem;
            top: .2rem;
            .iconfont {
                font-size:2rem;
                color: #c2d6fb;
            }
        }
    }
}
</style>
