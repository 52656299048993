var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "family-policy" },
    [
      _c("div", { staticClass: "policy-header" }, [
        _c("div", { staticClass: "username" }, [
          _vm._v("Hi, " + _vm._s(_vm.cname))
        ])
      ]),
      _c(
        "van-tabs",
        {
          attrs: { animated: "" },
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        _vm._l(_vm.members, function(item) {
          return _c("van-tab", { key: item.id, attrs: { title: item.name } })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "policy-list" },
        [
          _vm.policies.length == 0
            ? _c("div", { staticClass: "no-data" }, [
                _c("i", { staticClass: "iconfont icon-dingdan" }),
                _vm._v("暂无数据 ")
              ])
            : _vm._e(),
          _vm._l(_vm.policies, function(item) {
            return _c(
              "van-cell",
              { key: item.policyNo, staticClass: "policy-item" },
              [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.iname))
                ]),
                _vm._v(" " + _vm._s(item.prdName)),
                _c("br"),
                _vm._v(" 保单号：" + _vm._s(item.policyNo) + " "),
                _c("div", { staticClass: "status expired" }, [
                  _c("i", { staticClass: "iconfont icon-yishixiao" })
                ]),
                _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.handleViewDetail(item)
                        }
                      }
                    },
                    [_vm._v("查看详情")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      attrs: { href: item.elecPolicy, target: "_blank" }
                    },
                    [_vm._v("下载电子保单")]
                  )
                ])
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }