import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        signIn: true,
    },
    mutations: {
        SET_SIGN: (state, status) => {
            state.signIn = status;
        }
    },
    getters: {
        signIn(state){
            return state.signIn;
        }
    }
})

export default store;
