<template>
    <div class="article-detail">
        <div class="container">
            <h1 class="title">{{ model.title }}</h1>
            <div class="summary">
                <div class="left">
                    作者: {{ model.author }}
                </div>
                <div class="right">
                    {{ model.createTime }}
                </div>
            </div>
            <div class="content" v-html="model.content">
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/utils/request";

export default {
    data() {
        return {
            model: {}
        }
    },
    methods: {
        getData() {
            var urlParams = new URLSearchParams(location.href.split('?')[1]);
            if(urlParams.get('token')){
                localStorage.setItem("token", urlParams.get('token'));
            }
            axios.get("/cms/front/article/queryById",{
                    params: {id: this.$route.params.id, url: location.href, sourceId: urlParams.get('sourceId')},
                    headers: {'X-Access-Token': urlParams.get('token')}
                }).then(res => {
                this.model = res.result;
            });
        }
    },
    mounted() {
        this.getData();
    }
}
</script>

<style scoped lang="less">
.article-detail {
    height: 100%;
    overflow: auto;

    .container {
        padding: .3rem .2rem;
    }

    .title {
        margin-bottom: .1rem;
        font-size: .4rem;
    }

    .summary {
        color: #666;
        display: flex;
        justify-content: space-between;
        margin-bottom: .2rem;
    }

    .content {
        /deep/ img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }
}
</style>
