var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "assist-intro" },
    [
      _vm._m(0),
      _c("router-link", { staticClass: "u1", attrs: { to: "/assist2" } }, [
        _c("div", { staticClass: "link" }, [_vm._v(" 立即联系理赔顾问 ")])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "intro" }, [
      _c("img", {
        staticClass: "pic-intro",
        attrs: { src: "/images/img-intro-assist.jpg" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }