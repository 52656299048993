<template>
    <div class="family-policy">
        <div class="policy-header">
            <div class="username">Hi, {{ cname }}</div>
            <div class="link">
                <router-link to="/orderHistory">
                    历史保单
                </router-link>
            </div>
        </div>


        <div class="policy-card">
            <div class="item">
                <div class="item-header">
                    <span class="count">{{ totalPerson }}</span>人
                </div>
                <div class="content">保障人数</div>
            </div>
            <div class="item">
                <div class="item-header">
                    <span class="count">{{ list.length }}</span>张
                </div>
                <div class="content">有效保单数</div>
            </div>
            <div class="item">
                <div class="item-header">
                    ￥<span class="count">{{ totalPremium }}</span>
                </div>
                <div class="content">年度家庭总保费</div>
            </div>
        </div>

        <van-tabs v-model="active" animated @change="handleChange">
            <van-tab v-for="item in members" :key="item.id" :name="item.name"
                     :title="item.name + (item.count? '(' + item.count +')': '')"></van-tab>
        </van-tabs>
        <div class="policy-list">

            <div class="no-data" v-if="policies.length == 0">
                <i class="iconfont icon-dingdan"></i>暂无数据
            </div>

            <van-cell class="policy-item" v-for="item in policies" :key="item.policyNo">
                <div class="name">{{ item.iname }}</div>
                {{ item.prdName }}<br/>
                保单号：{{ item.policyNo }}
                <div class="status">
                    <i class="iconfont icon-baozhangzhong"></i>
                </div>
                <div>
                    <a href="javascript:void(0)" @click="handleViewDetail(item)" class="link">查看详情</a>
                    <a class="link" @click="handleElecPolicy(item.policyNo, item.elecPolicy)">下载电子保单</a>
                </div>
            </van-cell>
        </div>
    </div>
</template>

<script>
import axios from "@/utils/request";
import NP from 'number-precision'

export default {
    data() {
        return {
            active: "",
            name: "全部",
            list: [],
            totalPerson: 0,
            totalPremium: 0,
            cname: localStorage.getItem("cname")
        };
    },
    computed: {
        policies() {
            return this.list.filter(item => this.name == '全部' || item.holderName == this.name) || [];
        },
        members() {
            var result = [{id: 0, name: "全部"}];
            this.list.forEach(item => {
                let p = result.find(a => a.name == item.iname);
                if (p != null) {
                    p.count++;
                } else {
                    result.push({id: item.policyNo, name: item.iname, count: 1})
                    this.totalPerson++;
                }
                this.totalPremium = NP.plus(this.totalPremium, item.sbillAmount);
            });
            return result;
        }
    },
    methods: {
        getData() {
            axios.get("/usercenter/policy/list").then(res => {
                this.list = res.result || [];
            })
        },
        handleChange(name) {
            this.name = name;
        },
        handleViewDetail(data) {
            sessionStorage.setItem("policy", JSON.stringify(data));
            this.$router.push({path: "/policy"})
        },
        handleElecPolicy(policyNo, href) {
            axios.get("/usercenter/policy/downloadelecpolicy", {params: {policyNo}}).then(res => {
                var aLink = document.createElement("a");
                aLink.target = "_blank";
                aLink.href = href;
                aLink.click();
            })
        }
    },
    mounted() {
        this.getData()
    }
};
</script>

<style lang="less">
.family-policy {
    .policy-header {
        display: flex;
        justify-content: space-between;
        padding: .3rem;

        .username {
            color: var(--deepblue);
            font-weight: 600;
        }

        .link {
            line-height: .6rem;
            border-radius: .3rem;
            background-color: #3075f6;
            padding: 0 .4rem;

            a {
                color: #fff;
            }
        }
    }

    .policy-card {
        background: url(../assets/bg-card.jpg);
        background-size: cover;
        border-radius: 7px;
        margin: 0 0.3rem;
        display: flex;
        padding: .6rem 0;

        .item {
            width: 30%;
            text-align: center;
            padding: .1rem 0;
            border-right: 1px solid;
            border-image: linear-gradient(#296ef9, #2986fd, #296ef9) 1 10 1;

            &:last-child {
                width: 39%;
                border: none;
            }

            .item-header {
                color: #fff;
                margin-bottom: .2rem;

                .count {
                    font-weight: 600;
                    font-size: .6rem;
                }
            }

            .content {
                color: #9bcafe;
            }
        }
    }

    .van-tabs__nav {
        background-color: transparent;
    }

    .policy-list {
        margin: 0.2rem 0.3rem 0;

        .policy-item {
            background: #fff;
            border-radius: 8px;
            margin-bottom: 0.5rem;
            padding: 0.3rem 0 0.3rem 0.4rem;
            position: relative;

            .van-cell__value {
                overflow: visible;
            }

            .van-cell__value--alone {
                color: var(--gray);
            }

            .name {
                color: var(--deepblue);
                font-size: 0.4rem;
                font-weight: 600;
                margin-bottom: 0.1rem;
            }

            .link {
                color: var(--blue);
                margin-right: 0.5rem;
            }

            .status {
                position: absolute;
                right: -0.2rem;
                top: -0.2rem;

                .iconfont {
                    font-size: 2rem;
                    color: #c2d6fb;
                }
            }
        }
    }
}
</style>
