var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modify" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("账号与协议")]),
      _c(
        "van-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c("van-field", {
            attrs: {
              name: "原手机",
              label: "原手机",
              placeholder: "请输入原手机号",
              rules: [{ required: true }, { pattern: /^1[3456789]\d{9}$/ }]
            },
            model: {
              value: _vm.form.mobile,
              callback: function($$v) {
                _vm.$set(_vm.form, "mobile", $$v)
              },
              expression: "form.mobile"
            }
          }),
          _c("van-field", {
            attrs: {
              name: "新手机号",
              label: "新手机号",
              placeholder: "请输入新手机号",
              rules: [{ required: true }, { pattern: /^1[3456789]\d{9}$/ }]
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function() {
                  return [
                    _c(
                      "van-button",
                      {
                        attrs: {
                          size: "small",
                          type: "info",
                          "native-type": "button",
                          plain: "",
                          round: "",
                          disabled: _vm.isLocked
                        },
                        on: { click: _vm.handleAuthCode }
                      },
                      [_vm._v("获取验证码" + _vm._s(_vm.wait))]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.form.newMobile,
              callback: function($$v) {
                _vm.$set(_vm.form, "newMobile", $$v)
              },
              expression: "form.newMobile"
            }
          }),
          _c("van-field", {
            attrs: {
              name: "验证码",
              label: "验证码",
              placeholder: "请输入6位数短信验证码",
              rules: [{ required: true }, { pattern: /^\d{6}$/ }]
            },
            model: {
              value: _vm.form.auth,
              callback: function($$v) {
                _vm.$set(_vm.form, "auth", $$v)
              },
              expression: "form.auth"
            }
          }),
          _c(
            "div",
            { staticStyle: { margin: "16px" } },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    round: "",
                    block: "",
                    type: "info",
                    "native-type": "submit"
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }