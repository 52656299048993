var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "assist" },
    [
      _c("van-search", {
        attrs: { placeholder: "请输入您需要理赔的保单号" },
        model: {
          value: _vm.keywords,
          callback: function($$v) {
            _vm.keywords = $$v
          },
          expression: "keywords"
        }
      }),
      _c(
        "div",
        { staticClass: "policy-list" },
        [
          _vm.policyList.length == 0
            ? _c("div", { staticClass: "no-data" }, [
                _c("i", { staticClass: "iconfont icon-dingdan" }),
                _vm._v("暂无数据 ")
              ])
            : _vm._e(),
          _vm._l(_vm.policyList, function(item) {
            return _c(
              "van-radio-group",
              {
                key: item.policyNo,
                model: {
                  value: _vm.policyNo,
                  callback: function($$v) {
                    _vm.policyNo = $$v
                  },
                  expression: "policyNo"
                }
              },
              [
                _c(
                  "van-radio",
                  { attrs: { name: item.policyNo, "icon-size": "24px" } },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.iname))
                    ]),
                    _vm._v(" " + _vm._s(item.prdName)),
                    _c("br"),
                    _vm._v(" 保单号：" + _vm._s(item.policyNo) + " "),
                    _c("div", { staticClass: "status" }, [
                      _c("i", { staticClass: "iconfont icon-baozhangzhong" })
                    ])
                  ]
                )
              ],
              1
            )
          }),
          _vm.policyList.length
            ? _c(
                "van-button",
                {
                  attrs: { round: "", type: "info", block: "" },
                  on: { click: _vm.handleContact }
                },
                [_vm._v("联系理赔顾问")]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }