var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "article" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("服务协议")]),
      _c("p", [
        _vm._v(
          " 润迅电话商务公司拥有专业化的服务团队，团队成员在实践中积累了丰富的呼叫中心管理经验和技能。我们长期积累的独到的培训经验使得这些优秀的人才能够不断拓展自己的技能；润迅公司长期贯彻的“人性化管理”，确保我们为客户提供最好的专业化呼叫中心外包服务的企业目标，成为每一个员工的工作目标。 领先的技术、强大的网络：润迅呼叫中心的卓越服务不仅依赖于训练有素的优秀人才，也是润迅一贯保持技术领先的结果。先进的软硬件系统、经验丰富的技术队伍，使得润迅平台真正具备对不同行业、不同业务、不同规模、不同技术要求的适应性。尤其是润迅在北京、上海、广州、香港、深圳五个城市均设立电话服务中心，使得我们具备了业内领先的跨地域业务操作能力。 "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }