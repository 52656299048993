var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "header-info" }, [
      _c("div", { staticClass: "left u0" }, [
        _c("div", { staticClass: "wrapper-logo" }),
        _c(
          "div",
          [
            _c("router-link", { attrs: { to: "/login" } }, [
              _c("div", { staticClass: "link-login" }, [_vm._v("登录")])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "left u1" }, [
        _c("img", { staticClass: "avatar", attrs: { src: _vm.avatar } }),
        _c("div", [
          _c("div", { staticClass: "username" }, [
            _vm._v(_vm._s(_vm.model.cname || "　"))
          ]),
          _c("div", { staticClass: "mobile" }, [
            _c("i", { staticClass: "iconfont icon-mobile-phone" }),
            _vm._v(_vm._s(_vm._f("mobileMask")(_vm.model.mobile)) + " ")
          ])
        ])
      ]),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "item" }, [
          _c("i", {
            staticClass: "iconfont icon-service",
            on: { click: _vm.handleToIcs }
          })
        ])
      ])
    ]),
    _c("div", { staticClass: "sub-nav" }, [
      _c(
        "div",
        { staticClass: "item" },
        [
          _c(
            "router-link",
            { attrs: { to: _vm.$store.getters.signIn ? "/family" : "/login" } },
            [
              _c("van-button", [
                _c("i", { staticClass: "iconfont icon-baodanqueren" })
              ]),
              _c("div", { staticClass: "title" }, [_vm._v("家庭保单")])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "item" },
        [
          _c(
            "router-link",
            { attrs: { to: _vm.$store.getters.signIn ? "/order" : "/login" } },
            [
              _c("van-button", [
                _c("i", { staticClass: "iconfont icon-dingdan" })
              ]),
              _c("div", { staticClass: "title" }, [_vm._v("我的订单")])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "item" },
        [
          _c(
            "router-link",
            { attrs: { to: "/assist1" } },
            [
              _c("van-button", [
                _c("i", { staticClass: "iconfont icon-iconfont_qunfuben" })
              ]),
              _c("div", { staticClass: "title" }, [_vm._v("理赔协助")])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "item" },
        [
          _c(
            "router-link",
            { attrs: { to: _vm.$store.getters.signIn ? "/agent" : "/login" } },
            [
              _c("van-button", [
                _c("i", { staticClass: "iconfont icon-guwen" })
              ]),
              _c("div", { staticClass: "title" }, [_vm._v("我的顾问")])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "box u1" },
      [
        _c("van-cell", {
          attrs: { value: "查看全部", "is-link": "", to: "/family" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c("span", { staticClass: "cell-title" }, [
                    _vm._v(" 家庭保单总览 ")
                  ])
                ]
              },
              proxy: true
            }
          ])
        }),
        _c(
          "van-row",
          { staticClass: "family-policy" },
          [
            _c("van-col", { attrs: { span: "7" } }, [
              _c("strong", { staticClass: "count" }, [
                _vm._v(_vm._s(_vm.totalPerson))
              ]),
              _vm._v("人 "),
              _c("div", { staticClass: "content" }, [_vm._v("保障人数")])
            ]),
            _c("van-col", { attrs: { span: "7" } }, [
              _c("strong", { staticClass: "count" }, [
                _vm._v(_vm._s(_vm.list.length))
              ]),
              _vm._v("张 "),
              _c("div", { staticClass: "content" }, [_vm._v("有效保单数")])
            ]),
            _c("van-col", { attrs: { span: "10" } }, [
              _vm._v(" ￥"),
              _c("strong", { staticClass: "count" }, [
                _vm._v(_vm._s(_vm.totalPremium))
              ]),
              _c("div", { staticClass: "content" }, [_vm._v("年度家庭总保费")])
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "banner" },
      [
        _c("router-link", { attrs: { to: "/customization" } }, [
          _c("img", {
            attrs: { src: "/images/img-customization.jpg", alt: "" }
          })
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          "a",
          { attrs: { href: "tel:0755-36904299" } },
          [
            _c("van-cell", {
              attrs: { value: "0755-36904299" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "cell-title" }, [
                        _c("i", { staticClass: "iconfont icon-service" }),
                        _vm._v(" 联系客服 ")
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        ),
        _c("van-cell", {
          attrs: { "is-link": "", to: "/about" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c("span", { staticClass: "cell-title" }, [
                    _c("i", { staticClass: "iconfont icon-zhuanyequanwei" }),
                    _vm._v(" 关于我们 ")
                  ])
                ]
              },
              proxy: true
            }
          ])
        }),
        _c("van-cell", {
          attrs: { "is-link": "", to: "/settings" },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function() {
                return [
                  _c("span", { staticClass: "cell-title" }, [
                    _c("i", { staticClass: "iconfont icon-set" }),
                    _vm._v(" 设置 ")
                  ])
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }