<template>
    <div class="home">
        <div class="header-info">
            <div class="left u0">
                <div class="wrapper-logo"></div>
                <div>
                    <router-link to="/login">
                        <div class="link-login">登录</div>
                    </router-link>
                </div>

            </div>
            <div class="left u1">
                <img :src="avatar" class="avatar"/>
                <div>
                    <div class="username">{{ model.cname || '　' }}</div>
                    <div class="mobile">
                        <i class="iconfont icon-mobile-phone"></i>{{ model.mobile | mobileMask }}
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="item">
                    <i class="iconfont icon-service" @click="handleToIcs"></i>
                </div>
                <!--                <div class="item">-->
                <!--                    <router-link to="/settings">-->
                <!--                        <i class="iconfont icon-set"></i>-->
                <!--                    </router-link>-->
                <!--                </div>-->
            </div>
        </div>

        <div class="sub-nav">
            <div class="item">
                <router-link :to="$store.getters.signIn? '/family': '/login'">
                    <van-button>
                        <i class="iconfont icon-baodanqueren"></i>
                    </van-button>
                    <div class="title">家庭保单</div>
                </router-link>
            </div>
            <div class="item">
                <router-link :to="$store.getters.signIn? '/order': '/login'">
                    <van-button>
                        <i class="iconfont icon-dingdan"></i>
                    </van-button>
                    <div class="title">我的订单</div>
                </router-link>
            </div>
            <div class="item">
                <router-link to="/assist1">
                    <van-button>
                        <i class="iconfont icon-iconfont_qunfuben"></i>
                    </van-button>
                    <div class="title">理赔协助</div>
                </router-link>
            </div>
            <div class="item">
                <router-link :to="$store.getters.signIn? '/agent': '/login'">
                    <van-button>
                        <i class="iconfont icon-guwen"></i>
                    </van-button>
                    <div class="title">我的顾问</div>
                </router-link>
            </div>
        </div>

        <div class="box u1">
            <van-cell value="查看全部" is-link to="/family">
                <template #title>
                    <span class="cell-title">
                        家庭保单总览
                    </span>
                </template>
            </van-cell>
            <van-row class="family-policy">
                <van-col span="7">
                    <strong class="count">{{ totalPerson }}</strong>人
                    <div class="content">保障人数</div>
                </van-col>
                <van-col span="7">
                    <strong class="count">{{ list.length }}</strong>张
                    <div class="content">有效保单数</div>
                </van-col>
                <van-col span="10">
                    ￥<strong class="count">{{ totalPremium }}</strong>
                    <div class="content">年度家庭总保费</div>
                </van-col>
            </van-row>
        </div>

        <div class="banner">
            <router-link to="/customization">
                <img src="/images/img-customization.jpg" alt=""/>
            </router-link>
        </div>

        <div class="box">
            <a href="tel:0755-36904299">
                <van-cell value="0755-36904299">
                    <template #title>
                    <span class="cell-title">
                        <i class="iconfont icon-service"></i>
                        联系客服
                    </span>
                    </template>
                </van-cell>
            </a>
            <van-cell is-link to="/about">
                <template #title>
                    <span class="cell-title">
                        <i class="iconfont icon-zhuanyequanwei"></i>
                        关于我们
                    </span>
                </template>
            </van-cell>
            <van-cell is-link to="/settings">
                <template #title>
                    <span class="cell-title">
                        <i class="iconfont icon-set"></i>
                        设置
                    </span>
                </template>
            </van-cell>
        </div>
    </div>
</template>

<script>
import axios from "@/utils/request";
import avatar from "@/assets/avatar.jpg";
import NP from "number-precision";

export default {
    data() {
        return {
            avatar,
            model: {},
            totalPerson: 0,
            list: [],
            totalPremium: 0
        };
    },
    methods: {
        handleToIcs() {
            window.open("https://ics.demo.cmbpo.com/?id=Tfx1sA", "ics");
        },
        getData() {
            axios.get("/usercenter/getUserInfo").then(res => {
                this.model = res.result;
                this.getPolicy();
                this.$store.commit("SET_SIGN", true)
                localStorage.setItem("cname", res.result.cname);
                localStorage.setItem("mobile", res.result.mobile);
            }).catch(err => {
                console.log(err)
                this.$store.commit("SET_SIGN", false)
            })
        },
        getPolicy() {
            axios.get("/usercenter/policy/list").then(res => {
                this.list = res.result;
                let result = [];
                res.result.forEach(item => {
                    let p = result.find(a => a.name == item.iname);
                    if (p != null) {
                        p.count++;
                    } else {
                        result.push({id: item.policyNo, name: item.iname, count: 1})
                        this.totalPerson++;
                    }
                    this.totalPremium = NP.plus(this.totalPremium, item.sbillAmount);
                });
            })
        }
    },
    filters: {
        mobileMask(text) {
            return text ? text.replace(/(\d{3})\d{4}(\d{4})$/, '$1****$2') : '-';
        }
    },
    mounted() {
        this.getData();
    }
};
</script>

<style lang="less">
.home {
    .header-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.8rem 0.3rem 0.5rem;

        .left {
            display: flex;

            .wrapper-logo {
                width: 1.1rem;
                height: 1.1rem;
                border-radius: 50%;
                border: 0.05rem solid #fff;
                margin-right: 0.3rem;
                background: #fff url(/logo.png) no-repeat center center;
                background-size: 90%;
            }

            .avatar {
                width: 1.1rem;
                height: 1.1rem;
                border-radius: 50%;
                border: 0.05rem solid #fff;
                margin-right: 0.3rem;
            }

            .link-login {
                line-height: 1.1rem;
                font-size: .4rem;
                font-weight: 600;
            }

            .username {
                color: var(--deepblue);
                font-size: 0.35rem;
                font-weight: 600;
                margin-bottom: 0.1rem;
            }

            .mobile {
                background: #2a97fe;
                border-radius: 0.5rem;
                padding: .05rem 0.3rem 0;
                color: #fff;
                font-size: .2rem;

                .iconfont {
                    font-size: .4rem;
                    vertical-align: middle;
                }
            }
        }

        .right {
            .item {
                display: inline-block;
                margin-left: 10px;
            }

            .iconfont {
                color: var(--deepblue);
                font-size: .6rem;
            }
        }
    }

    .sub-nav {
        margin: 0 0.3rem;
        display: flex;
        justify-content: space-between;

        .item {
            text-align: center;

            .title {
                color: var(--deepblue);
            }
        }

        .van-button {
            background: #2d6ef0;
            color: #fff;
            border-radius: 0.38rem;
            width: 1.2rem;
            height: 1.2rem;
            padding: 0;
            margin-bottom: 0.2rem;

            .iconfont {
                font-size: 0.6rem;
            }
        }
    }

    .family-policy {
        color: #111b36;
        text-align: center;
        padding: 0.2rem 0;

        .count {
            font-size: 0.5rem;
        }

        .content {
            color: var(--gray);
        }
    }

    .banner {
        margin: .3rem 0.3rem 0;

        img {
            width: 100%;
        }
    }
}
</style>
