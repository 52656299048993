<template>
    <div class="modify">
        <div class="title">账号与协议</div>
        <van-cell class="u1">
            <template #title>
                手机号码 <span class="mobile">{{ mobile }}</span>
            </template>
        </van-cell>
        <van-cell title="协议与声明" is-link to="/protocol"></van-cell>
        <van-cell class="logout u1" @click="handleLogout">
            <template #title>
                退出
            </template>
        </van-cell>
    </div>
</template>

<script>
import axios from "@/utils/request";
export default {
    data() {
        return {
            mobile: localStorage.getItem("mobile")
        };
    },
    methods: {
        handleAuthCode() {
            this.isLocked = true;
            this.wait = "(" + this.time + ")";
            let countdown = this.time;
            let timer = setInterval(() => {
                countdown--;
                if (countdown == 0) {
                    clearInterval(timer);
                    this.wait = "";
                    this.isLocked = false;
                    countdown = this.time;
                    return;
                }
                this.wait = "(" + countdown + ")";
            }, 1000);
        },
        handleLogout() {
            this.$dialog
                .confirm({
                    message: "确定退出登录吗？"
                })
                .then(() => {
                    this.$router.push({ path: "/login" });
                    localStorage.removeItem("cname")
                    localStorage.removeItem("token")
                })
                .catch(() => {});
        }
    }
};
</script>

<style lang="less">
.modify {
    .title {
        font-weight: 600;
        padding: 10px 16px;
    }
    .mobile {
        color: #969799;
    }

    .logout {
        margin-top: 0.5rem;
        text-align: center;
    }
}
</style>
