<template>
  <div class="customization">
      <img src="/images/customization/1.jpg" alt="">
      <img src="/images/customization/2.jpg" alt="">
      <img src="/images/customization/3.jpg" alt="">
      <img src="/images/customization/4.jpg" alt="">
      <img src="/images/customization/5.jpg" alt="">
      <img src="/images/customization/6.jpg" alt="">
      <img src="/images/customization/7.jpg" alt="">
      <img src="/images/customization/8.jpg" alt="">
      <img src="/images/customization/9.jpg" alt="">
      <img src="/images/customization/10.jpg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
.customization {
    img {
        width:100%;
        vertical-align: top;
    }
}
</style>