import axios from "axios";
import { Dialog } from 'vant';

//axios.defaults.withCredentials = true
const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 5000
});

service.interceptors.request.use(
    config => {
        config.headers['X-Access-Token'] = localStorage.getItem("token");
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

service.interceptors.response.use(
    response => {
        // if(response.data.code == 500){
        //     Dialog.alert({message: response.data.message});
        //     response.data.result = {};
        // }
        // return response.data;
        switch(response.data.code){
            case 500:
                Dialog.alert({message: response.data.message});
                response.data.result = {};
                break;
            case 301:
            case 302:
                top.location.href = response.data.result;
                break;
            case 401:
                top.location.href = '/#/login';
                break;
            default:
                return response.data;
        }
    },
    err => {
        var response = err.response;
        switch(response.status){
            case 500:
                Dialog.alert({message: response.data.message});
                response.data.result = {};
                break;
            case 301:
            case 302:
                console.log(response);
                top.location.href = response.headers.location || '/';
                break;
            case 401:
                top.location.href = '/#/login';
                break;
            default:
                return Promise.reject(err);
        }
    }
);

export default service;
