<template>
    <div class="assist-intro">
        <div class="intro">
            <img src="/images/img-intro-assist.jpg" class="pic-intro" />
        </div>
        <router-link to="/assist2" class="u1">
            <div class="link">
                立即联系理赔顾问
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    }
};
</script>

<style lang="less">
.assist-intro {
    .intro {
        .pic-intro {
            width: 100%;
            vertical-align: top;
        }
    }
    .link {
        background-color: #ff9900;
        text-align: center;
        color: #fff;
        font-weight: 600;
        padding: 0.3rem 0;
        font-size: 0.45rem;
    }
}
</style>
