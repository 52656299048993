var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "article-detail" }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.model.title))]),
      _c("div", { staticClass: "summary" }, [
        _c("div", { staticClass: "left" }, [
          _vm._v(" 作者: " + _vm._s(_vm.model.author) + " ")
        ]),
        _c("div", { staticClass: "right" }, [
          _vm._v(" " + _vm._s(_vm.model.createTime) + " ")
        ])
      ]),
      _c("div", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.model.content) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }