import { render, staticRenderFns } from "./OrderHistory.vue?vue&type=template&id=54c0e0c0&"
import script from "./OrderHistory.vue?vue&type=script&lang=js&"
export * from "./OrderHistory.vue?vue&type=script&lang=js&"
import style0 from "./OrderHistory.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/9sgAcqLa/0/insurance/frontend/h5.demo.cmbpo.com/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54c0e0c0')) {
      api.createRecord('54c0e0c0', component.options)
    } else {
      api.reload('54c0e0c0', component.options)
    }
    module.hot.accept("./OrderHistory.vue?vue&type=template&id=54c0e0c0&", function () {
      api.rerender('54c0e0c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/OrderHistory.vue"
export default component.exports