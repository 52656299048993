var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modify" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("账号与协议")]),
      _c("van-cell", {
        staticClass: "u1",
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(" 手机号码 "),
                _c("span", { staticClass: "mobile" }, [
                  _vm._v(_vm._s(_vm.mobile))
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("van-cell", {
        attrs: { title: "协议与声明", "is-link": "", to: "/protocol" }
      }),
      _c("van-cell", {
        staticClass: "logout u1",
        on: { click: _vm.handleLogout },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(" 退出 ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }