var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "article" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("关于我们")]),
      _c("p", [
        _vm._v(
          " 润迅电话商务有限公司是润迅通信集团下属全资子公司，于1997年开始从事呼叫中心业务。依托润迅集团在中国市场积累的全方位通信营运经验，及其在国内通信行业独树一帜的品牌管理、服务管理和技术管理，润迅呼叫中心在吸收国外呼叫中心先进经验的前提下，积极总结通信服务经验，逐步形成了适合中国国情的呼叫中心管理理念和运营手段。公司在北京、上海、广州、深圳和香港建立了具有国际先进水平的联网呼叫中心平台，并在全国范围内申请了95803统一接入号，为国内外企业用户提供全方位外包呼叫中心服务。 凭借大陆的成本优势，结合国际先进的管理理念和方法，通过向客户提供高水准的服务，润迅逐步展开面向海外市场的业务，并在美国设有接入平台，自设中美间的光纤电路，目前已运作的专案涉足北美地区有线电视、金融服务、电信服务和家庭保安等行业。我们正在进一步积累运作跨境业务的经验，面向国际市场的战略视野、一批成长中的跨境业务运作管理人员和多语种的话务员队伍，使得我们在跨境业务服务领域拥有更坚实的基础。 "
        )
      ]),
      _c("p", [
        _vm._v(
          " 润迅呼叫中心为客户提供每天24小时、每周7天的全程不间断服务，满足全球时差的需要；服务语种包括普通话、粤语、英语、韩语、各地方言等。经过多年的不懈努力，润迅电话商务公司已发展成为国内最大的外包呼叫中心之一，拥有外包服务座席逾千个，话务代表4000多人，业务涉及电信、金融保险、旅游、IT、家电、制药和汽车等领域。 我们的目标——创建卓越的电信服务品牌，成为中国最好的专业化外包呼叫中心服务商。 无论您需要全部还是部分外包您的客户关系管理项目，润迅电话商务都是您明智的选择，因为我们具备： 丰富的运营经验：润迅集十余年的通信服务及客户服务经验，在呼叫中心运营方面有着深厚的实践与理论积累，形成了独具优势的客户服务能力和电话营销能力。电话销售已成为润迅呼叫中心的核心业务之一，在通过电话挖掘新客户,稳定老客户,对高价值客户的再销售方面积累了丰富的经验。润迅呼叫中心善于针对您的特殊需求灵活运用，在尽可能短的时间内把您的业务以及企业价值观念与我们的专长结合，为客户提供全面、最佳的CRM解决方案。 "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }