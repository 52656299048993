<template>
    <div class="modify">
        <div class="title">账号与协议</div>
        <van-form @submit="onSubmit">
            <van-field
                v-model="form.mobile"
                name="原手机"
                label="原手机"
                placeholder="请输入原手机号"
                :rules="[{ required: true }, {pattern: /^1[3456789]\d{9}$/}]"
            />
            <van-field
                v-model="form.newMobile"
                name="新手机号"
                label="新手机号"
                placeholder="请输入新手机号"
                :rules="[{ required: true }, {pattern: /^1[3456789]\d{9}$/}]"
            >
                <template #button>
                    <van-button
                        size="small"
                        type="info"
                        native-type="button"
                        plain
                        round
                        @click="handleAuthCode"
                        :disabled="isLocked"
                        >获取验证码{{ wait }}</van-button
                    >
                </template>
            </van-field>
            <van-field
                v-model="form.auth"
                name="验证码"
                label="验证码"
                placeholder="请输入6位数短信验证码"
                :rules="[{ required: true }, {pattern: /^\d{6}$/}]"
            >
            </van-field>
            <div style="margin: 16px">
                <van-button round block type="info" native-type="submit"
                    >提交</van-button
                >
            </div>
        </van-form>
    </div>
</template>

<script>
import axios from "@/utils/request";
export default {
    data() {
        return {
            isLocked: false,
            wait: "",
            form: {
                mobile: "",
                auth: ""
            },
            time: 60
        };
    },
    methods: {
        handleAuthCode() {
            this.isLocked = true;
            this.wait = "(" + this.time + ")";
            let countdown = this.time;
            let timer = setInterval(() => {
                countdown--;
                if (countdown == 0) {
                    clearInterval(timer);
                    this.wait = "";
                    this.isLocked = false;
                    countdown = this.time;
                    return;
                }
                this.wait = "(" + countdown + ")";
            }, 1000);
        },
        onSubmit() {
            console.log(11);
        }
    }
};
</script>

<style lang="less">
.modify {
    .title {
        font-weight: 600;
        padding:10px 16px;
    }
}
</style>
