var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agent" }, [
    _c("div", { ref: "wrapper", staticClass: "wrapper" }, [
      _c("div", { staticClass: "agent-card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("div", { staticClass: "agent-avatar" }, [
            _c("img", {
              staticClass: "img-avatar",
              attrs: { src: _vm.avatar, alt: "" }
            })
          ]),
          _vm._m(0),
          _vm._m(1)
        ]),
        _c(
          "div",
          { staticClass: "agent-grade" },
          [
            _c(
              "van-row",
              [
                _c("van-col", { attrs: { span: "6" } }, [
                  _c("div", { staticClass: "count" }, [_vm._v("7.5")]),
                  _vm._v(" 从业年限 ")
                ]),
                _c("van-col", { attrs: { span: "6" } }, [
                  _c("div", { staticClass: "count" }, [_vm._v("170")]),
                  _vm._v(" 服务客户 ")
                ]),
                _c("van-col", { attrs: { span: "6" } }, [
                  _c("div", { staticClass: "count" }, [_vm._v("315")]),
                  _vm._v(" 保单 ")
                ]),
                _c("van-col", { attrs: { span: "6" } }, [
                  _c("div", { staticClass: "count" }, [_vm._v("44")]),
                  _vm._v(" 理赔(万) ")
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "contact-type" },
      [
        _c(
          "van-row",
          { attrs: { gutter: "10" } },
          [
            _c("van-col", { attrs: { span: "12" } }, [
              _c(
                "div",
                { staticClass: "item", on: { click: _vm.handleQrCode } },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("i", { staticClass: "iconfont icon-weixin" }),
                    _vm._v("加微信 ")
                  ]),
                  _vm._v(" 随时答疑解惑 ")
                ]
              )
            ]),
            _c("van-col", { attrs: { span: "12" } }, [
              _c("a", { attrs: { href: "tel:0755-36904299" } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("i", { staticClass: "iconfont icon-dianhua" }),
                    _vm._v("打电话 ")
                  ]),
                  _vm._v(" 立即咨询 ")
                ])
              ])
            ])
          ],
          1
        )
      ],
      1
    ),
    _c("h3", { staticClass: "box-title" }, [_vm._v("个人标签")]),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agent-name" }, [
      _c("div", { staticClass: "name" }, [_vm._v("陈倩倩")]),
      _c("div", { staticClass: "job" }, [_vm._v("资深保险代理人")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agent-sign" }, [
      _c("div", { staticClass: "sign" }, [
        _c("i", { staticClass: "iconfont icon-jinpaizixun" }),
        _vm._v(" 金牌顾问 ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box tags" }, [
      _c("div", { staticClass: "tag" }, [_vm._v("财富管理")]),
      _c("div", { staticClass: "tag" }, [_vm._v("专业负责")]),
      _c("div", { staticClass: "tag" }, [_vm._v("敬业爱岗")]),
      _c("div", { staticClass: "tag" }, [_vm._v("资深保险顾问")]),
      _c("div", { staticClass: "tag" }, [_vm._v("理赔专家")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }