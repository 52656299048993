<template>
    <div id="app" :class="$store.getters.signIn? 'SIGN': 'UNSIGN'">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {

    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    background: url(assets/bg-main.png) no-repeat right -40px;
    max-width:768px;
    margin: 0 auto;
    box-shadow: 0 0 3px #000;
}
</style>
