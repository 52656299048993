// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1623295975804");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1623295975804");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1623295975804");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"iconfont\"; /* Project id 2595475 */\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n\n.iconfont {\n  font-family: \"iconfont\" !important;\n  font-size: 0.34261rem;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-yishixiao:before {\n  content: \"\\e64d\";\n}\n\n.icon-bojinguwen:before {\n  content: \"\\e613\";\n}\n\n.icon-mobile-phone:before {\n  content: \"\\e759\";\n}\n\n.icon-logobg-copy:before {\n  content: \"\\e7cb\";\n}\n\n.icon-jinpaizixun:before {\n  content: \"\\e775\";\n}\n\n.icon-baozhangzhong:before {\n  content: \"\\e73e\";\n}\n\n.icon-my:before {\n  content: \"\\e60f\";\n}\n\n.icon-all-fill:before {\n  content: \"\\e777\";\n}\n\n.icon-logistics-icon-fill:before {\n  content: \"\\e783\";\n}\n\n.icon-zhuanyequanwei:before {\n  content: \"\\e7ca\";\n}\n\n.icon-szb-logo:before {\n  content: \"\\e60d\";\n}\n\n.icon-service:before {\n  content: \"\\e764\";\n}\n\n.icon-set:before {\n  content: \"\\e76c\";\n}\n\n.icon-search:before {\n  content: \"\\e7b3\";\n}\n\n.icon-viewlist:before {\n  content: \"\\e76d\";\n}\n\n.icon-guwen:before {\n  content: \"\\e60c\";\n}\n\n.icon-baodanqueren:before {\n  content: \"\\e692\";\n}\n\n.icon-dingdan:before {\n  content: \"\\e693\";\n}\n\n.icon-iconfont_qunfuben:before {\n  content: \"\\e636\";\n}\n\n.icon-weixin:before {\n  content: \"\\e6cd\";\n}\n\n.icon-dianhua:before {\n  content: \"\\e677\";\n}\n\n.icon-haoyoutuijian:before {\n  content: \"\\e691\";\n}\n\n", ""]);
// Exports
module.exports = exports;
