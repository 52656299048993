var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "policy" },
    [
      _c("div", { staticClass: "policy-header" }, [
        _c("div", { staticClass: "policy-name" }, [
          _vm._v(" " + _vm._s(_vm.model.prdName) + " ")
        ]),
        _vm._v(" 保单号：" + _vm._s(_vm.model.policyNo) + " "),
        _c("i", { staticClass: "iconfont icon-baozhangzhong" })
      ]),
      _c(
        "div",
        { staticClass: "policy-body" },
        [
          _c(
            "van-row",
            [
              _c("van-col", { staticClass: "title", attrs: { span: "6" } }, [
                _vm._v(" 保险期间"),
                _c("br"),
                _vm._v(" 投保人"),
                _c("br"),
                _vm._v(" 被保人"),
                _c("br"),
                _vm._v(" 保费"),
                _c("br"),
                _vm._v(" 承保公司 ")
              ]),
              _c("van-col", { attrs: { span: "18" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.model.insStime) +
                    "至" +
                    _vm._s(_vm.model.insEtime)
                ),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.model.holderName)),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.model.iname)),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.model.sbillAmount) + "元"),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.model.comName) + " ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.handleElecPolicy(
                _vm.model.policyNo,
                _vm.model.elecPolicy
              )
            }
          }
        },
        [
          _c("van-cell", { attrs: { "is-link": "" } }, [
            _c(
              "span",
              {
                staticClass: "cell-title",
                attrs: { slot: "title" },
                slot: "title"
              },
              [_vm._v("查看电子保单")]
            )
          ])
        ],
        1
      ),
      _c(
        "van-cell",
        { attrs: { "is-link": "" }, on: { click: _vm.handleContact } },
        [
          _c(
            "span",
            {
              staticClass: "cell-title",
              attrs: { slot: "title" },
              slot: "title"
            },
            [_vm._v(" 理赔协助 ")]
          )
        ]
      ),
      _c(
        "a",
        { attrs: { href: "tel:0755-36904299" } },
        [
          _c("van-cell", { attrs: { value: "4006-11-7777" } }, [
            _c(
              "span",
              {
                staticClass: "cell-title",
                attrs: { slot: "title" },
                slot: "title"
              },
              [_vm._v(" 拨打保险公司电话 ")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }