<template>
    <div class="order">
        <van-tabs v-model="active" animated @change="handleChange">
            <van-tab v-for="item in tabs" :key="item" :name="item" :title="item"></van-tab>
        </van-tabs>
        <div class="order-list">
            <div class="no-data" v-if="orders.length == 0">
                <i class="iconfont icon-dingdan"></i>暂无数据
            </div>
            <div class="order-item" v-for="item in orders" :key="item.orderNo">
                <div class="order-header">
                    <div class="time">下单时间：{{ item.createTime }}</div>
                    <div class="status">{{ item.payStatus }}</div>
                </div>
                <div class="order-body">
                    <div class="order-pic">
                        <img src="/images/pic-prod.jpg" alt="" class="img-prod" />
                    </div>
                    <div class="order-desc">
                        <div class="title">{{ item.prdName }}</div>
                        <div class="content">订单类型：{{ item.orderType }}</div>
                    </div>
                    <div class="order-price">
                        <div class="real-price">￥{{item.orderAmount}}</div>
                        <div class="history-price">(原价￥{{item.payAmount}})</div>
                        <div class="count">X1</div>
                    </div>
                </div>
<!--                <div class="order-footer">-->
<!--                    <van-button plain type="info">取消订单</van-button>-->
<!--                    <van-button type="info">继续支付</van-button>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/utils/request";
export default {
    data() {
        return {
            tabs: ["全部", "已支付", "待支付", "已取消"],
            active: "",
            name: "全部",
            list: []
        };
    },
    computed: {
        orders() {
            return this.list.filter(item => this.name == '全部' || item.payStatus == this.name) || [];
        }
    },
    methods: {
        getData() {
            axios.get("/usercenter/orderlist").then(res => {
                this.list = res.result;
            })
        },
        handleChange(name) {
            this.name = name;
        },
    },
    mounted() {
        this.getData()
    }
};
</script>

<style lang="less">
.order {
    .van-tabs__wrap {
        margin: 0;
    }
}
.order-list {
    padding-top: .2rem;
    margin: 0 0.3rem;
    .order-item {
        border-radius: .2rem;
        background: #fff;
        margin-bottom: .5rem;
        .order-header {
            padding: 0 .3rem;
            line-height: 1rem;
            display: flex;
            justify-content: space-between;
            border-bottom: .01rem solid #ccc;
            .time {
                color: var(--gray);
            }
            .status {
                color: var(--blue);
            }
        }

        .order-body {
            display: flex;
            justify-content: space-between;
            padding: .3rem;
            line-height: 150%;
            .order-pic {
                width: 1.3rem;
                flex-shrink: 0;
                flex-grow: 0;
                .img-prod {
                    width: 100%;
                    max-width: 100px;
                }
            }

            .order-desc {
                flex-grow: 1;
                padding-left: .3rem;
                color: var(--gray);
                .title {
                    color: var(--deepblue);
                    font-weight: 600;
                }
            }

            .order-price {
                white-space: nowrap;
                text-align: right;
                color: var(--gray);
                .real-price {
                    color: var(--deepblue);
                    font-weight: 600;
                }
            }
        }

        .order-footer {
            text-align: right;
            padding: 0 0 .3rem;
            .van-button {
                padding: 0 .3rem;
                height: .6rem;
                margin-right:.3rem;
                border-radius: .4rem;
            }
        }
    }
}
</style>
