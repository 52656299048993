import Vue from "vue";
import App from "./App.vue";
import Vant from "vant";
import store from './store'
//import 'amfe-flexible/index.js'
import "vant/lib/index.css";
import "./styles/iconfont/iconfont.css";

import "./styles/base.less";

import router from "./router";

Vue.use(Vant);

Vue.config.productionTip = false;
Vue.prototype.domainURL = process.env.VUE_APP_API_BASE_URL + "/";

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount("#app");
